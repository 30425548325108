import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import { Helmet } from 'react-helmet'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'

import Header from '../../components/Header/Header'
import Layout from '../../components/Layout/Layout'

import '../../styles/employment.css'

const Employment = ({ data }) => {

  return (
    <Layout title={data.markdownRemark.frontmatter.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={data.markdownRemark.frontmatter.headerImage.photo} alt={data.markdownRemark.frontmatter.headerImage.altText}>{data.markdownRemark.frontmatter.heading}</Header>
      <div className="main">
        {data.markdownRemark.frontmatter.intro ? <ReactMarkdown children={data.markdownRemark.frontmatter.intro} /> : null}
        {data.markdownRemark.frontmatter.openJobs ? (
          <Accordion allowZeroExpanded>
            {data.markdownRemark.frontmatter.openJobs.map(job => (
              <AccordionItem key={job.position}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h2>{job.position}</h2>
                    <div className="detail-expand">
                      {job.deadline ? (
                        <small><strong>Deadline:</strong> {moment(job.deadline).format('MMMM DD, YYYY')}</small>
                      ) : null}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ReactMarkdown children={job.apply} />
                  <ReactMarkdown children={job.description} />
                  <ReactMarkdown children={job.apply} />
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        ) : (
            <h2>{data.markdownRemark.frontmatter.noJobs}</h2>
          )}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query EmploymentQuery {
  markdownRemark(frontmatter: {title: {eq: "Employment"}}) {
    frontmatter {
      heading
      intro
      openJobs {
        deadline
        description
        position
      }
      noJobs
      apply
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
    }
  }
}
`

export default Employment